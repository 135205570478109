import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import assistantIcon from '../assets/images/icon-app.svg';
import emptyUserProfileIcon from '../assets/images/user-profile-img.svg';
import { HomeworkData, HomeworkImageSignUrl } from '../interface/HomeWorkHistoryInterface';

const authKey = process.env.REACT_APP_AUTH_KEY

declare global {
    interface Window {
        markdownToHTML: (text: string, options?: any) => string;
    }
}

function HomeWorkHistory(): JSX.Element {

    const [specificHomeworkHistory, setSpecificHomeworkHistory] = useState<HomeworkData | null>(null);
    const [imageData, setImageData] = useState<HomeworkImageSignUrl | null>(null);
    const [profileImageData, setProfileImageData] = useState<any>(null);
    const [homeworkDiscussionDiv, setHomeworkDiscussionDiv] = useState<JSX.Element[] | null>(null);
    const [loadingState, setLoadingState] = useState<boolean>(true);
    const [convertFunctionRunConditionState, setConvertFunctionRunConditionState] = useState<boolean>(false);
    const [homeworkDiscussionDataStatus, setHomeworkDiscussionDataStatus] = useState<boolean>(false);

    const location = useLocation();

    const getHomeworkHistory = async (encryptedParams: string) => {
        const data = {
            "encrypted_data": encryptedParams
        };

        try {
            const response = await axios.post(
                'https://us-central1-snapstudy-ai.cloudfunctions.net/fn_snap_study_get_shared_single_homework_history_api',
                data,
                { headers: { 'Content-Type': 'application/json', 'authorization': authKey } }
            );

            if (response.status === 200) {
                const responseData = response.data;
                if (responseData.data) {
                    setSpecificHomeworkHistory(responseData.data);
                    if (responseData.data?.homework_details.length > 1) {
                        setHomeworkDiscussionDataStatus(true)
                        setTimeout(() => {
                            setConvertFunctionRunConditionState(true);
                        }, 1500)
                    } else {
                        setConvertFunctionRunConditionState(true);
                    }
                }
            } else {
                console.error('Network response was not ok.');
            }
        } catch (error) {
            console.error('Error:', error);
            // Handle error, e.g., set an error state
        }
    };

    const getImageData = async () => {
        const imageName = specificHomeworkHistory?.homework_details[0]?.image_name;

        if (imageName) {
            const data = {
                "signed_url_generator_data": {
                    "image_name": imageName
                }
            };

            try {
                const response = await axios.post(
                    'https://us-central1-snapstudy-ai.cloudfunctions.net/fn_snap_study_download_sign_url_from_image_name',
                    data,
                    { headers: { 'Content-Type': 'application/json', 'authorization': authKey } }
                );

                if (response.status === 200) {
                    setImageData(response.data);
                } else {
                    console.error('Network response was not ok.');
                }
            } catch (error) {
                console.error('Error:', error);
                // Handle error, e.g., set an error state
            }
        }
    };

    const getProfileImage = async () => {
        const profileImageName = specificHomeworkHistory?.user_details?.profile_image;

        if (profileImageName) {
            const data = {
                "signed_url_generator_data": {
                    "profile_image_name": profileImageName
                }
            };

            try {
                const response = await axios.post(
                    'https://us-central1-snapstudy-ai.cloudfunctions.net/fn_snap_study_sign_url_from_profile_image_name',
                    data,
                    { headers: { 'Content-Type': 'application/json', 'authorization': authKey } }
                );

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData) {
                        setProfileImageData(responseData);
                    }
                } else {
                    console.error('Network response was not ok.');
                }
            } catch (error) {
                console.error('Error:', error);
                // Handle error, e.g., set an error state
            }
        }
    };

    const homeworkDiscussionData = () => {
        const discussionDivs: JSX.Element[] = [];
        if (specificHomeworkHistory?.homework_details) {
            for (let i = 1; i < specificHomeworkHistory?.homework_details?.length; i++) {
                discussionDivs.push(
                    <div className="contentBlock" key={i}>
                        <div className="chatWrap msgRight">
                            <span className="msgAvatar"><img src={profileImageData ? profileImageData?.sign_url : emptyUserProfileIcon} alt="User Profile" /></span>
                            <div className="msgBubble">
                                <span className="latexTextString">{specificHomeworkHistory?.homework_details[i].last_user_message}</span>
                            </div>
                        </div>
                        <div className="chatWrap msgLeft">
                            <span className="msgAvatar icon-app"><img src={assistantIcon} alt="Assistant Icon" /></span>
                            <div className="msgBubble">
                                <span className="latexTextString">{specificHomeworkHistory?.homework_details[i].latest_assistant_response}</span>
                            </div>
                        </div>
                    </div>
                );
            }
        }
        setHomeworkDiscussionDiv(discussionDivs);
    };

    const timeDifferenceFunction = (providedTimeString: string) => {
        const createdOn = moment.utc(providedTimeString);
        const now = moment();

        const duration = moment.duration(now.diff(createdOn));

        const years = duration.years();
        const months = duration.months();
        const weeks = duration.weeks();
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        return formatDuration(years, months, weeks, days, hours, minutes, seconds);
    };

    const formatDuration = (
        years: number,
        months: number,
        weeks: number,
        days: number,
        hours: number,
        minutes: number,
        seconds: number
    ) => {
        let timeAgo = '';
        let unitCount = 0;

        if (years > 0) {
            timeAgo += `${years} year${years > 1 ? 's' : ''} `;
            unitCount++;
            if (unitCount === 2) return timeAgo.trim() + ' ago';
        }

        if (months > 0 && months < 12) {
            timeAgo += `${months} month${months > 1 ? 's' : ''} `;
            unitCount++;
            if (unitCount === 2) return timeAgo.trim() + ' ago';
        }

        if (weeks > 0 && weeks < 5) {
            timeAgo += `${weeks} week${weeks > 1 ? 's' : ''} `;
            unitCount++;
            if (unitCount === 2) return timeAgo.trim() + ' ago';
        }

        if (days > 0 && days < 7) {
            timeAgo += `${days} day${days > 1 ? 's' : ''} `;
            unitCount++;
            if (unitCount === 2) return timeAgo.trim() + ' ago';
        }

        if (hours > 0 && hours < 24) {
            timeAgo += `${hours} hour${hours > 1 ? 's' : ''} `;
            unitCount++;
            if (unitCount === 2) return timeAgo.trim() + ' ago';
        }

        if (minutes > 0 && minutes < 60) {
            timeAgo += `${minutes} minute${minutes > 1 ? 's' : ''} `;
            unitCount++;
            if (unitCount === 2) return timeAgo.trim() + ' ago';
        }

        if (seconds > 0 && seconds < 60) {
            timeAgo += `${seconds} second${seconds > 1 ? 's' : ''} `;
            unitCount++;
            if (unitCount === 2) return timeAgo.trim() + ' ago';
        }

        return 'Just now';
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const encryptedParams = queryParams.get('encryptedParams');
        const regularString = encryptedParams?.slice(2, -1);

        if (regularString) {
            getHomeworkHistory(regularString)
        }
    }, [location.search]);

    useEffect(() => {
        if (specificHomeworkHistory) {
            getImageData();
            getProfileImage();
        }
    }, [specificHomeworkHistory]);

    useEffect(() => {
        if (specificHomeworkHistory?.homework_details && specificHomeworkHistory?.homework_details?.length > 1) {
            homeworkDiscussionData();
        }
    }, [homeworkDiscussionDataStatus, profileImageData])

    useEffect(() => {
        if (convertFunctionRunConditionState) {
            setLoadingState(false);
            const convert = () => {
                const elements: any = document.getElementsByClassName('latexTextString');

                for (const element of elements) {
                    const latexText = element.textContent?.trim();

                    if (latexText && latexText.length > 0) {
                        const options = {
                            htmlTags: true,
                            outMath: { //You can set which formats should be included into html result
                                include_mathml: true,
                                include_asciimath: true,
                                include_latex: true,
                                include_svg: true, // sets in default
                                include_tsv: true,
                                include_table_html: true, // sets in default
                                include_mathml_word: true
                            }
                        };
                        const html = window.markdownToHTML(latexText, options);

                        if (element.innerHTML.trim() !== html.trim()) {
                            element.innerHTML = html;
                        }
                    }
                }
            };

            setTimeout(() => {
                convert();
            }, 1500);
        }
    }, [convertFunctionRunConditionState]);

    if (loadingState) {
        // Render a loader while loading
        return <div className="loader-container">
            <div className="loader"></div>
        </div>;
    }

    return (
        <div className="container">
            <div className="titleBlock">
                <span className="app-icon icon-app icon-32 mr-10" />
                <a href="https://snapstudy.ai/"><h1>SnapStudy.<span className='colorSkyBlue'>a</span><span className='colorRed'>i</span> FEED</h1></a>
            </div>
            <div className="feedTitleBlock">
                <div>
                    <h2>{specificHomeworkHistory?.homework_title}</h2>
                    <span className="date text-secondary">{
                        (specificHomeworkHistory?.homework_details) ? timeDifferenceFunction(specificHomeworkHistory?.homework_details[0]?.created_at) : null
                    }</span>
                </div>
            </div>
            <div className="cardBody">
                <div className="contentBlock captureImg">
                    <div className="imgContainer">
                        <img src={imageData?.sign_url} alt="Homework_image" />
                    </div>
                </div>
            </div>

            <div className="cardBody">
                <div className="cardTitle flexDisplay">
                    <h4 style={{ marginLeft: 0, borderLeft: 0, }}><strong>SnapStudy.<span className='colorSkyBlue'>a</span><span className='colorRed'>i</span>&nbsp;&nbsp;&nbsp;ANALYSIS</strong></h4>
                    <h3><strong>Identified Question</strong></h3>
                </div>
                <div className="contentBlock">
                    <span className="latexTextString">{specificHomeworkHistory?.homework_details[0]?.last_user_message}</span>
                </div>
            </div>

            {
                specificHomeworkHistory?.homework_details[0]?.latest_assistant_response ? (<div className="cardBody">
                    <div className="cardTitle">
                        <h3><strong>Answer</strong></h3>
                    </div>
                    <div className="contentBlock">
                        <span className="latexTextString">{specificHomeworkHistory?.homework_details[0]?.latest_assistant_response}</span>
                    </div>
                </div>) : null
            }
            {
                homeworkDiscussionDataStatus ? (
                    <div className="cardBody">
                        <div className="cardTitle">
                            <h3><strong>Homework Discussion</strong></h3>
                        </div>
                        {homeworkDiscussionDiv}
                    </div>
                ) : null
            }
        </div >
    )
}

export default HomeWorkHistory;
